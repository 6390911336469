import React from 'react'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  text: {
    textAlign: 'center',
    fontSize: '2em',
  },
  btn: {
    marginTop: '1em',
  },
  root: {
    flexGrow: 1,
  },
})

export default function HomePage({ data }) {
  const classes = useStyles()
  const image = data.file.publicURL

  return (
    <Layout>
      <Grid container className={classes.root} alignItems='center'>
        <Grid item xs={12} md={6} className={classes.text}>
          <h2>California Realtor</h2>
          <h3>Find your perfect house</h3>
          <Link to='/properties'>
            <Button className={classes.btn} variant='contained'>
              See Houses
            </Button>
          </Link>
        </Grid>

        <Grid item xs={12} md={6}>
          <img width='75%' src={image} alt='' />
        </Grid>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query HeroImage {
    file(relativePath: { eq: "hero_image.png" }) {
      publicURL
    }
  }
`
